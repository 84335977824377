@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply h-full;
}

/* @see https://blog.marclucraft.co.uk/masonry-layout-with-tailwindcss */
@layer utilities {
  @variants responsive {
    .masonry-2 {
      column-count: 2;
    }
    .masonry-3 {
      column-count: 3;
    }
    .masonry-4 {
      column-count: 4;
    }
    .break-inside {
      break-inside: avoid;
    }
  }
}
